import './BountyPending.scss';
import { useState } from "react";
import { Bounty } from "../../types";
import { formatMoney } from "../../globals";
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { fundBounty } from '../../api';
import { useUserContext } from '../../contexts/UserContext';

interface BountyPendingProps {
  bounty: Bounty;
}

export default function BountyPending({ bounty }: BountyPendingProps) {
  const navigate = useNavigate();
  const { setUser } = useUserContext();
  const [funded, setFunded] = useState(false);
  const [loadedBounty, setLoadedBounty] = useState(bounty);

  function bountyClicked() {
    navigate(`/bounty/${bounty._id}`);
  }

  async function boostClicked() {
    const result = await fundBounty(bounty._id, 1, 0); 
    if (result.status !== 200) {
      console.error('Fund Bounty Error: ' + result.status);
    } else {
      setUser(result.data.user);

    }
  }

  return (
    <div className='bounty-pending' onClick={bountyClicked}>
      <div className='top'>
        <div className='title'>{loadedBounty.title}</div>
        <Button
          variant='contained'
          onClick={(event) => {
            event.stopPropagation();
            boostClicked();
          }}
        >
          <div className='fund'>
            <FontAwesomeIcon className='icon' icon={faFire} />
            <span className='text'>Boost</span>
          </div>
        </Button>
      </div>
      <div className='bottom'>
        <div className='phrase'>Freshly Posted Fantasy - Give It the Boost It Needs!</div>
        <div className='amount'>Bounty: {formatMoney(loadedBounty.silver)}</div>
      </div>
    </div>
  )
}