import './ClaimActiveBar.scss';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Vote } from '../../types';

interface ClaimActiveBarProps {
  vote: Vote;
  onVote: (votes: number) => void;
}

export default function ClaimActiveBar({ vote, onVote }: ClaimActiveBarProps) {

  return (
    <div className='claim-active-bar'>
      {
        [1, 2, 3, 4, 5].map(votes => (
          (vote.unresolved || vote.votes > 0) ? 
          <FontAwesomeIcon key={votes} className={`vote ${vote.votes >= votes ? 'selected' : 'disabled'}`} icon={faStar} />
          :
          <FontAwesomeIcon key={votes} className='vote active' icon={faStar} onClick={()=>onVote(votes)} />
        ))
      }
    </div>
  )
}