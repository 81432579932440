import { useParams } from 'react-router-dom';
import './ClaimPage.scss';
import { useEffect, useState } from 'react';
import { Bounty, Claim, Hunter, Vote } from '../../types';
import { getClaim, getVote, postVote } from '../../api';
import BountyBar from './BountyBar';
import { useAppContext } from '../../contexts/AppContext';
import ClaimBar from './ClaimBar';
import BountyFeed from './BountyFeed';
import HunterBar from './HunterBar';
import MediaViewer from '../MediaViewer';
import ScrollToTop from '../ScrollToTop';
import { bountyActive } from '../../globals';
import ClaimActiveBar from './ClaimActiveBar';
import { useUserContext } from '../../contexts/UserContext';

export default function ClaimPage() {
  const { id } = useParams();
  const { snackError } = useAppContext();
  const { setUser } = useUserContext();
  const [claim, setClaim] = useState({ unresolved: true } as Claim);
  const [bounty, setBounty] = useState({ unresolved: true } as Bounty);
  const [hunter, setHunter] = useState({ unresolved: true } as Hunter);
  const [vote, setVote] = useState({ unresolved: true } as Vote);

  useEffect(() => {
    setClaim({ unresolved: true } as Claim);
    setBounty({ unresolved: true} as Bounty);
    setHunter({ unresolved: true } as Hunter);
    setVote({ unresolved: true } as Vote);
    if (id) {
      getClaim(id).then(result => {
        if (result.status !== 200) {
          snackError("Error loading claim: " + result.status);
          console.error('getClaim: %d', result.status);
        } else {
          setClaim(result.data);
          setBounty(result.data.bounty);
          setHunter(result.data.hunter);
        }
      });
      getVote(id).then(result => {
        setVote(result.status !== 200 ? { } as Vote : result.data);
      });
    }
  }, [id]);

  async function handleVote(votes: number) {
    try {
      setVote({ unresolved: true } as Vote);
      const result = await postVote(claim._id, votes);
      if (result.status !== 200) {
        console.error('Post Vote Error: ' + result.status);
        setVote({ } as Vote);
      } else {
        setVote(result.data.vote);
        setClaim(result.data.claim);
        setBounty(result.data.bounty);
        setUser(result.data.user);
      }
    }
    catch (error) {
      console.error("Vote Clicked Error: ", error);
      setVote({ } as Vote);
    }
  }
  
  return (
    <div className='claim-page'>
      <ScrollToTop />
      <div className='claim-box'>
        <BountyBar bounty={bounty} />
        <MediaViewer file={claim.file} />
        <ClaimBar claim={claim} />
        {
          bountyActive(bounty) === 2 && 
          <ClaimActiveBar vote={vote} onVote={handleVote} />
        }
      </div>
      <HunterBar hunter={hunter} />
      { claim.bountyId && <BountyFeed search={bounty.title} claimId={claim._id} excludeId={claim.bountyId} /> }
    </div>
  );
}