import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './StatusBar.scss';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from '../contexts/UserContext';

export default function StatusBar() {
  const { user } = useUserContext();


  return (
    <div className='status-bar'>
      <div className='metric'>
        <FontAwesomeIcon className='icon' icon={faStar} />
        {user.silver}
      </div>
      <div className='metric'>
        <FontAwesomeIcon className='icon' icon={faFire} />
        {user.votes}
      </div>
    </div>
  );
}