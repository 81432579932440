import { Link, Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import "./MainLayout.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import SearchDialog from "../SearchDialog";
import NavBar from "../NavBar";
import { Button } from "@mui/material";
import { useUserContext } from "../../contexts/UserContext";
import { ProtectedRoute } from "../ProdtectedRoute";
import StatusBar from "../StatusBar";

export default function MainLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    setSearchOpen(false);
  },[location]);

  function searchClicked() {
    setSearchOpen(true);
  }

  async function userClicked() {
    navigate("/account");
  }

  return (
    <ProtectedRoute role={3} active>
      <div className="main-layout">
        <div className="main-layout-inner">
          <div className="main-bar">
            <Button variant="contained" onClick={searchClicked}>Search</Button>
            <Link className="logo" to="/">
              Bounty
            </Link>
            <div className="user" onClick={userClicked}>
              Hello, {user.handle}
              <FontAwesomeIcon icon={faUserCircle} />
            </div>
          </div>
          <NavBar />
          <main>
            <Outlet />
          </main>
        </div>
        { searchOpen && <SearchDialog open={searchOpen} setOpen={setSearchOpen} /> }
        <StatusBar />
      </div>
    </ProtectedRoute>
  );
}
