import './Login.scss';
import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { authenticate, getUser } from '../../api';
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate } from 'react-router-dom';
import useAutofillDetector from '../../hooks/useAutofillDetector';
import { useUserContext } from '../../contexts/UserContext';
import PageFooter from '../PageFooter';
import PageHeader from '../PageHeader';

export default function Login() {
  const navigate = useNavigate();
  const { user, setUserFull } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [email, setEmail] = useState('');
  const [emailAutofilled, setEmailAutofilled] = useAutofillDetector();
  const [password, setPassword] = useState('');
  const [passwordAutofilled, setPasswordAutofilled] = useAutofillDetector();

  useEffect(() => {
    if (user._id) {
      navigate('/');
    }
  },[user]);
 
  async function loginClicked() {
    try {
      setLoading(true);
      const authenticateResult = await authenticate(email, password);
      if (authenticateResult.status === 400) {
        setLoginError("Invalid login credentials. Please try again.");
      } else if (authenticateResult.status !== 200) {
        setLoginError("Error authenticate (" + authenticateResult.status + ")");
      } else {
        console.log("TOKEN SET: " + authenticateResult.data._id);
        localStorage.setItem('ss', authenticateResult.data._id);
        const userResult = await getUser();
        if (userResult.status !== 200) {
          setLoginError("Error loading user data. Please try again");
        } else {
          setUserFull(userResult.data);
        }
      }
    }
    catch (error) {
      setLoginError("Error " + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='main-body'>
      <PageHeader showLogin={false} />
        <div className='form-page'>
          <h1>Sign In</h1>
          <TextField 
            inputProps={{ onAnimationStart: setEmailAutofilled }}
            InputLabelProps={{ shrink: email.length > 0 || emailAutofilled }}
            label="Email"
            value={email}
            onChange={(event) => {
              setLoginError('');
              setEmail(event.target.value);
            }}
          />
          <TextField 
            inputProps={{ onAnimationStart: setPasswordAutofilled }}
            InputLabelProps={{ shrink: password.length > 0 || passwordAutofilled }}
            type='password'
            label="Password"
            value={password}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                loginClicked();
              }
            }}
            onChange={(event) => {
              setLoginError('');
              setPassword(event.target.value);
            }}
          />
          {loginError && (
            <Alert severity="error">{loginError}</Alert>
          )}
          <LoadingButton 
            variant="contained" 
            loading={loading} 
            onClick={loginClicked}>
            <span>Sign In</span>
          </LoadingButton>
          <div className='forgot-password'>
            <Link to='/forgot'>Forgot password?</Link>
          </div>
          <p>New to Bounty? <Link to='/register/member'>Sign up now</Link></p>
          <p>Earn money making content? <Link to='/register/creator'>Become a creator</Link></p>
        </div>
      <PageFooter />
    </div>
  );
}
